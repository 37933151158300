/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Image, Button } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Reference"}>
        <SiteHeader />

        <Column className="--style3 bg--center --full" name={"wstęp"} style={{"backgroundColor":"var(--color-supplementary)"}} fullscreen={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--1 flex--center" anim={"2"} animS={"4"} style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="--left pb--02 pl--02 pr--02 pt--02" anim={null} animS={null} style={{"maxWidth":1200}}>
              
              <Title className="title-box title-box--shadow5 fs--102" content={"<span style='color: white'>Rekomendacje</span>"}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-1);\">W zdrowym ciele zdrowy duch.</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":900}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"rekomendacje"}>
          
          <ColumnWrap className="column__flex --left el--2 pb--20 pl--25 pr--25 pt--20" style={{"maxWidth":1280}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--43" content={"“Piotr jest trenerem, z którym trening jest po prostu przyjemny.”"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach."}>
              </Text>

              <Subtitle className="subtitle-box" content={"— Anita Kruk"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--2 mt--30 pb--20 pl--25 pr--25 pt--20" style={{"maxWidth":1280}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--43" content={"“Piotr jest trenerem, z którym trening jest po prostu przyjemny.”"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach."}>
              </Text>

              <Subtitle className="subtitle-box" content={"— Anita Kruk"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--1 mt--30 pb--20 pl--25 pr--25 pt--20" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/107/img-1_s=350x_.jpg"} svg={false} ratio={"16:9"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/107/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/107/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/107/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/107/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/107/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--2 mt--30 pb--30 pl--25 pr--25 pt--20" style={{"maxWidth":1280}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--43" content={"“Piotr jest trenerem, z którym trening jest po prostu przyjemny.”"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach."}>
              </Text>

              <Subtitle className="subtitle-box" content={"— Anita Kruk"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1jz76ti --style3 bg--center --full" name={"wstęp"} style={{"backgroundColor":"var(--color-supplementary)"}} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s4" anim={"7"} animS={"4"}>
              
              <Title className="title-box fs--72" content={"<span style='color: white'>Wstępny <span style='color: var(--color-variable-1);'>darmowe konsultacje</span>.</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--30 mt--20" content={"<span style='color: white'>Treningi indywidualne od 45 zł/godz.</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--cbtn2 btn-box--filling2 fs--20 swpf--uppercase mt--30" href={"/pl/kontakt"} content={"Umów spotkanie"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"stopka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3 pb--16 pt--10" style={{"maxWidth":1200}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--24 w--600 swpf--uppercase" content={"<span style=\"color: var(--color-custom-1);\">Piotr Wieczorkowski</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--20 mt--02" content={"<span style='color: var(--color-dominant)'>Trener personalny</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

              <Text className="text-box" content={"<a href=\"https://saywebpage.com\">Tworzenie stron</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/pl/"} content={"<span style='color: var(--color-dominant)'>Wstęp"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/pl/usługi"} content={"<span style='color: var(--color-dominant)'>Usługi"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/pl/rekomendacje"} content={"<span style='color: var(--color-dominant)'>Rekomendacje"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/pl/kontakt"} content={"<span style='color: var(--color-dominant)'>Kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}